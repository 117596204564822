
export const navItems = [
    {
        title: 'Fees Management.',
        route: '/fees-management',
        children: [
            {
                title: 'Minting Fees Management.',
                route: '/minting-fee',
            },
            {
                title: 'Royalty Fees Management.',
                route: '/royalty-fee',
            },
            {
                title: 'Partner Fees Management.',
                route: '/partner-fee',
            },
            {
                title: 'Platform Fees Management.',
                route: '/platform-fee',
            }
        ],
    },

    {
        title: 'Whitelist Management.',
        route: '/whitelist-management',
    },
    {
        title: 'NFT Trait.',
        route: '/nft-trait',
    },
    {
        title: 'Collections Management.',
        route: '/collections-management',
    },
    {
        title: 'Marketplace Management.',
        route: 'market-place',
        children: [
            {
                title: 'Borrow and Lend.',
                route: '/market-place/borrow-and-lend',
            },
            {
                title: 'Currency.',
                route: '/market-place/currency-management',
            },
            {
                title: 'Manage Document.',
                route: '/market-place/manage-document',
            },
            {
                title: 'Kyc.',
                route: '/market-place/kyc-management',
            },
        ]
    },
    {
        title: 'Email Management.',
        route: '/email',
        children: [
            {
                title: 'Partner Management',
                route: '/email/partner-management',
            },
            {
                title: 'Delivery Management',
                route: '/email/delivery-management',
            }
        ]
    },

    {
        title: 'Faq Management.',
        route: '/faq',
    },

    {
        title: 'Website Visitors.',
        route: '/website-visitors',
    },
    {
        title: 'Settings.',
        route: '/settings',
    }
]